export enum NavigationRoutes {
  Catalog = 'catalog',
  Offers = 'offers',
  Guudie = 'guudie',
  Checkout = 'checkout',
  Profile = 'profile',
  Manage = 'manage',
  Login = 'login',
  Logout = 'logout',
  SetPassword = 'set-password',
}
